import React from 'react';
import Helmet from 'react-helmet';
import { Link } from '@latitude/link';
import { StaticImage } from 'gatsby-plugin-image';
import { BUTTON_STYLE } from '@latitude/core/utils/constants';
import { StickyNavigation } from '@latitude/sticky-navigation';
import Hero from '../../components/Hero/Hero';
import Section from '../../components/Section/Section';
import SvgInline from '../../components/SvgInline/SvgInline';
import Callout from '../../components/Callout/Callout';
import CalloutTitle from '../../components/Callout/CalloutTitle';
import CalloutBody from '../../components/Callout/CalloutBody';
import CalloutIcon from '../../components/Callout/CalloutIcon';
import Layout from '../../components/layout';
import Faq from '../../components/Faq/Faq';
import CardIcon from '../../components/CardIcon/CardIcon';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';
import PageData from '../../data/pages/loans/personal-loan-insurance.json';

const PersonalLoanInsurancePage = props => (
  <Layout location={props.location}>
    <MobileAppInstallPrompts />
    <main className="navigation-spacer">
      <Helmet>
        <link
          rel="canonical"
          href="https://www.latitudefinancial.com.au/loans/personal-loan-insurance/"
        />
        <title>{PageData.metaTitle}</title>
        <meta name="description" content={PageData.metaDesc} />
      </Helmet>
      <Hero
        h1="Personal loan insurance"
        intro="Guard yourself against the unexpected with Loan Protection from Latitude Insurance. You can apply for this cover when taking out your loan."
        bg={require('../../images/hero/banner-pl-loan-insurance.jpg')}
        darkBg
      />

      <div className="d-none d-lg-block">
        <StickyNavigation
          items={PageData.nav}
          offsetElem="[data-sticky-navigation-offset]"
        />
      </div>

      <Section id="whats-covered" className="bg-lightest">
        <h2 className="text-center">We’ve got you covered</h2>
        <div className="row">
          <div className="col-md-6 col-lg-3 mb-5">
            <CardIcon
              leftAligned
              small
              image={
                <SvgInline
                  name="tailor-made-loans"
                  className="illustration-icon"
                />
              }
              title="Protection for you"
            >
              Build your own plan with a range of covers to suit your needs and
              lifestyle.
            </CardIcon>
          </div>
          <div className="col-md-6 col-lg-3 mb-5">
            <CardIcon
              leftAligned
              small
              image={
                <SvgInline
                  name="application-process"
                  className="illustration-icon"
                />
              }
              title="Easy application"
            >
              Add insurance when you apply for your loan.
            </CardIcon>
          </div>
          <div className="col-md-6 col-lg-3 mb-5">
            <CardIcon
              leftAligned
              small
              image={
                <SvgInline name="two-in-one" className="illustration-icon" />
              }
              title="2 payments in 1"
            >
              Premium added to your loan so you only have one regular loan
              payment.
            </CardIcon>
          </div>
          <div className="col-md-6 col-lg-3 mb-5">
            <CardIcon
              leftAligned
              small
              image={
                <SvgInline name="payments" className="illustration-icon" />
              }
              title="Fair’s fair"
            >
              You’ll always pay the same regardless of your age, job or medical
              history.
            </CardIcon>
          </div>
        </div>
      </Section>

      <Callout>
        <div className="row">
          <div className="col-12 col-lg-7">
            <CalloutTitle title="Didn’t find what you were looking for?" />
          </div>
          <div className="col-12 col-lg-5">
            <CalloutBody
              content={
                <React.Fragment>
                  <strong>
                    Call our insurance specialists now on 1800 734 583
                  </strong>
                  <br />
                  Mon - Fri, 8am - 7pm (AEST)
                </React.Fragment>
              }
            />
          </div>
        </div>
      </Callout>

      <Section id="cover-types" className="bg-lightest content-pane">
        <h2 className="text-center">
          Tailor your plan with one or more of the following covers
        </h2>
        <div className="row">
          <div className="col-md-4">
            <h5 className="mb-0">Disability Plus</h5>
            <p>
              We’ll cover your repayments, up to $1,500 per month, if you can’t
              work due to sickness or injury. Waiting and qualifying periods may
              apply.
            </p>
          </div>
          <div className="col-md-4">
            <h5 className="mb-0">Involuntary Unemployment Cover</h5>
            <p>
              If you lose your job through no fault of your own, we’ll cover
              your repayments, up to $1,500 per month, until you find a job, or
              for a maximum of 6 months.
            </p>
          </div>
          <div className="col-md-4">
            <h5 className="mb-0">Cash Boost Benefit</h5>
            <p>
              If you become seriously ill, you could be eligible for a one-off
              cash boost benefit.
            </p>
          </div>
        </div>
        <p>
          Terms, limits and exclusions apply. Please refer to the{' '}
          <a
            href="https://assets.latitudefinancial.com/legals/product-disclosure-statements/loan-repayment-protection/pds.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Combined Financial Services Guide, Product Disclosure Statement and
            Policy
          </a>
          .
        </p>
        <p>
          This{' '}
          <a
            href="https://assets.latitudefinancial.com/brochures/au/complaints-brochure.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            brochure
          </a>{' '}
          is your guide to making a complaint about your insurance policy.
        </p>
      </Section>

      <Section id="how-to-get-it" className="bg-brand">
        <h2 className="text-center text--invert">
          Safeguard your loan in 3 simple steps
        </h2>
        <div className="row">
          <div className="col-md-4">
            <h4 className="text--invert mb-0">1. Apply online</h4>
            <p className="text--invert">Apply for a personal loan</p>
          </div>
          <div className="col-md-4">
            <h4 className="text--invert mb-0">2. Discuss insurance</h4>
            <p className="text--invert">
              We can take you through the benefits and features, so you can
              choose the plan that’s right for you.
            </p>
          </div>
          <div className="col-md-4">
            <h4 className="text--invert mb-0">3. Opt-in</h4>
            <p className="text--invert">
              If your loan is approved, you can then add insurance.
            </p>
          </div>
        </div>
      </Section>

      <Callout>
        <div className="row">
          <div className="col-xl-3">
            <CalloutIcon icon={<SvgInline name="claim" strokeBlueDark />} />
          </div>
          <div className="col-12 col-xl-6 d-flex flex-column justify-content-center">
            <CalloutTitle
              className="h-auto mb-4"
              title="Need to make a claim?"
            />
            <CalloutBody
              className="h-auto"
              content="We're here to help you every step of the way."
            />
          </div>
          <div className="col-10 offset-1 col-md-4 offset-md-4 col-xl-3 offset-xl-0 d-flex align-items-center">
            <Link
              button={BUTTON_STYLE.TERTIARY_INVERSE}
              href="/insurance/make-a-claim?tabtoopen=personalLoan"
              trackId="personal-loan-insurance--make-a-claim--lets-get-started"
              width="100%"
            >
              Let&apos;s get started
            </Link>
          </div>
        </div>
      </Callout>

      <Faq className="faq--pli__lists" data={PageData.faq} />

      <Section className="bg-lightest section-shadow">
        <div className="row">
          <div className="col-md-12">
            <p>
              This insurance is issued by Hallmark General Insurance Company
              Ltd. ABN 82 008 477 647 (AFSL 243478). Hallmark General and
              Hallmark Life are part of the Latitude group. Any advice on this
              website does not take into account your personal objectives,
              financial situation or needs. You should consider if this advice
              is right for you and read the relevant Financial Services Guide
              (FSG), Product Disclosure Statement and Policy (PDS) before
              deciding whether to acquire the insurance.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <p>
              Hallmark General is a subscriber to the General Insurance Code of
              Practice. Click the subscriber badge to access a copy of the Code.
            </p>
            <a
              href="http://codeofpractice.com.au/"
              target="_blank"
              rel="noopener noreferrer"
              className="insurance-logo-link"
            >
              <StaticImage
                className="content-image"
                src="../../../static/media/logos/insurance-cop-subscriber.jpg"
                alt="General Insurance Code of Practice Subscriber"
              />
            </a>
          </div>
          <div className="col-md-6">
            <p>
              Hallmark Life is part of the Latitude group and is a subscriber to
              the Life Insurance Code of Practice. Click the subscriber badge to
              access a copy of the Code.
            </p>
            <a
              href="https://www.fsc.org.au/policy/life-insurance/code-of-practice/"
              target="_blank"
              rel="noopener noreferrer"
              className="insurance-logo-link"
            >
              <StaticImage
                className="content-image"
                src="../../../static/media/logos/life-code-logo-170.png"
                alt="Life Insurance Code of Practice"
              />
            </a>
          </div>
        </div>
      </Section>
    </main>
  </Layout>
);

export default PersonalLoanInsurancePage;
